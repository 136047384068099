import { useLazyQuery } from '@apollo/client';
import { TPartyIds, TPartyImageResponse, TPartyImageVars } from './types';
import { PARTY_GET_IMAGE } from '../graphql/partyGetSpotifyImages';
import { useEffect } from 'react';

export const usePartyImage = ({ partyId, partyUAID, nameId, isni, spotifyId }: TPartyIds) => {
  const [getPartyImages ,{ loading, data }] = useLazyQuery<TPartyImageResponse, TPartyImageVars>(PARTY_GET_IMAGE, {
    fetchPolicy: 'no-cache' //this is applied because when you save a party the current image is not updated
  });

  useEffect(() => {
    const isLookupPage = window.location.pathname.includes('isni-search');

    // since the lookup page just sends the spotifyId to this hook, this condition is being used to prevent
    // unnecessary request to the API when spotifyId is empty.
    if(isLookupPage && !spotifyId)
      return;

    getPartyImages({
      variables: {
        data: {
          partyId,
          partyUAID,
          nameId,
          isni,
          spotifyId
        },
      },
    });
  }, []);

  return {
    loadingImages: loading,
    dataImages: data?.partyGetSpotifyImages.images
  };
};
